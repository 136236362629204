<template>
  <div>
    <v-container>
      <page-title title="Customer"></page-title>
      <v-row>
        <v-col>
          <list-data></list-data>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ListData from './components/ListData.vue';
export default {
  components: { ListData },
};
</script>